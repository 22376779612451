/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 17:35:11
 * @Module: 系统通知
 */
 <template>
  <div class="box"
       v-loading="loading">
    <div class="item"
         v-for="(item,index) in list"
         :key="index">
      <div class="item-t">
        <span class="item-t-1">{{item.title}}</span>
        <span class="item-t-2">{{item.created_at}}</span>
      </div>
      <div class="item-b">{{item.description}}</div>
    </div>
    <el-pagination layout="prev, pager, next"
                   style="display: flex;justify-content: center;"
                   v-show="pagination.page"
                   :current-page="pagination.page"
                   @current-change="currentChange"
                   :page-size="pagination.pageSize"
                   hide-on-single-page
                   :total="pagination.total">
    </el-pagination>
    <no-data v-show="pagination.total==0&& !loading"></no-data>
  </div>
</template>
 <script>
import noData from "@/components/noData"
export default {
  components: {
    noData
  },
  data () {
    return {
      loading: true,
      pagination: {
        total: 0,
        pageSize: 20,
        page: null,
      },
      list: []
    };
  },
  mounted () { },
  methods: {
    currentChange (page) {
      this._get_list(page)
    },
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.noticeSystemNoticeApi({ page })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }
    }
  },
  created () {
    this._get_list()
  }
};
 </script>
 <style lang='scss' scoped>
@import "../style.scss";
.box {
  padding: 20px 0px;
}
.item {
  width: 844px;
  min-height: 50px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px 16px;
  margin: 0 auto;
  margin-bottom: 30px;
  &-t {
    font-size: 14px;
    line-height: 20px;
    &-1 {
      color: #333333;
    }
    &-2 {
      color: #999999;
    }
  }
  &-b {
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }
}
</style>